import { useState } from 'react';
import { workflowEvents } from '@/main/analytics/workflowEvents';
import { useAppMetadata } from '@/main/contexts/app-metadata/AppMetadata';
import { sendAnalytics } from '@/main/initializers/analytics';
import { DEFAULT_PAGE_SIZE } from '@/shared/design-system/v2/core/data-display/table/util';
import { useGetWorkflowRunsQuery } from '../../../../queries/workflows/list/list';
import { WorkflowRunsListWrapper } from './WorkflowRunsList.wrapper';
import { WorkflowRunTabs } from './WorkflowRunsListTabs';
import { executedFromBuilderFilter, runTabFilter, upcomingRunsFilter } from './util';

interface WorkflowRunsListContainerProps {
  workflowId: string;
  isPanelExpanded: boolean;
}

export const WorkflowRunsListContainer = ({
  workflowId,
  isPanelExpanded,
}: WorkflowRunsListContainerProps): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState(WorkflowRunTabs.ALL);
  const { workspaceId } = useAppMetadata();

  const filters =
    activeTab === WorkflowRunTabs.UPCOMING
      ? [upcomingRunsFilter]
      : [runTabFilter, executedFromBuilderFilter];

  // For runs in "Runs" tab sort using startDate, and for runs in upcoming tab use scheduledTime
  const sortKey = activeTab === WorkflowRunTabs.UPCOMING ? 'scheduledTime' : 'startDate';

  const { data, isError, isFetching } = useGetWorkflowRunsQuery({
    workflowId: workflowId,
    start: (currentPage - 1) * DEFAULT_PAGE_SIZE,
    limit: DEFAULT_PAGE_SIZE,
    sortKey: sortKey,
    filters,
  });

  const handlePageNumberChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleTabChange = (tabId: WorkflowRunTabs) => {
    setCurrentPage(1);
    setActiveTab(tabId);
    if (tabId === WorkflowRunTabs.ALL) {
      sendAnalytics(
        workflowEvents.runs.workflowRunsRunsTabitemClicked({
          workspaceId: workspaceId,
          workflowId: workflowId,
        }),
      );
    } else if (tabId === WorkflowRunTabs.UPCOMING) {
      sendAnalytics(
        workflowEvents.runs.workflowRunsUpcomingTabitemClicked({
          workspaceId: workspaceId,
          workflowId: workflowId,
        }),
      );
    }
  };

  return (
    <WorkflowRunsListWrapper
      workflowId={workflowId}
      isLoading={isFetching}
      isError={isError}
      workflowRuns={data?.response ?? []}
      currentPage={currentPage}
      onPageChange={handlePageNumberChange}
      totalRows={data?.numRecords ?? 0}
      activeTab={activeTab}
      onTabChange={handleTabChange}
      isPanelExpanded={isPanelExpanded}
    />
  );
};
