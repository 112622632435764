import { IconExclamationCircleFilled } from '@tabler/icons-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { sendAnalytics } from '@/main/initializers/analytics';
import {
  Alert,
  Button,
  Horizontal,
  ScrollArea,
  Text,
  Vertical,
  closeAllModals,
  notifications,
  useInputState,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';
import { workflowEvents } from '../../../../analytics';
import { useAppMetadata } from '../../../../contexts/app-metadata/AppMetadata';
import { WorkflowScheduleType } from '../../../../generated/api';
import {
  useEmailTriggerPublishMutation,
  usePublishScheduledWorkflowMutation,
} from '../../../../queries/workflows/debug';
import { getErrorMessageFromCode } from '../../create/utils';
import { DateTimePickersContainer } from '../DateTimePickersContainer';
import { EmailTrigger } from '../EmailTrigger';
import { ScheduleConfig } from '../ScheduleConfig';
import { TriggerTypeSelect } from '../TriggerSelect';
import { useInitialDates } from '../useDateHooks';
import { TriggerType, scheduledTriggerOptions } from '../util';

interface ScheduleContainerProps {
  workflowId: string;
}

export const ScheduleContainer = ({ workflowId }: ScheduleContainerProps): JSX.Element => {
  const theme = useMarkovTheme();

  const { featureScheduledWorkflows, featureWflowsEmailTrigger } = useFlags();

  const { workspaceId } = useAppMetadata();

  const { mutateAsync: publishEmailTriggerWorkflow, isLoading: isEmailTriggerPublishing } =
    useEmailTriggerPublishMutation(workflowId);
  const { mutate: scheduleWorkflow, isLoading: isScheduling } =
    usePublishScheduledWorkflowMutation(workflowId);

  const [errorMsg, setErrorMsg] = useState('');

  const defaultTriggerType = TriggerType.PERIODIC;

  const [scheduleType, setScheduleType] = useInputState<WorkflowScheduleType>(
    WorkflowScheduleType.Periodic,
  );

  const [triggerType, setTriggerType] = useInputState<TriggerType>(defaultTriggerType);

  const [intervalInput, setIntervalInput] = useInputState(30);
  const [frequency, setFrequency] = useInputState('');

  const { startDate, setStartDate, endDate, setEndDate } = useInitialDates();

  const handleCancel = () => {
    sendAnalytics(
      workflowEvents.runs.workflowRunsScheduleModalClosed({
        workflowId,
        workspaceId,
      }),
    );
    closeAllModals();
  };

  const handleEmailTriggerPublish = async () => {
    try {
      await publishEmailTriggerWorkflow();
      closeAllModals();
      notifications.success('Workflow published successfully');
    } catch (error: any) {
      setErrorMsg(getErrorMessageFromCode(error.response?.data?.detail?.error_code ?? ''));
      logger.error(error);
    }
  };

  const handleScheduleClick = () => {
    sendAnalytics(
      workflowEvents.publish.publishClicked({ runType: triggerType, workflowId, workspaceId }),
    );
    sendAnalytics(
      workflowEvents.runs.workflowRunsScheduleButtonClicked({
        workflowId,
        workspaceId,
      }),
    );
    if (triggerType === TriggerType.EMAIL) {
      handleEmailTriggerPublish();
    } else if (scheduledTriggerOptions.some(option => option.value === triggerType)) {
      scheduleWorkflow(
        {
          schedule: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            interval: scheduleType === WorkflowScheduleType.Periodic ? intervalInput : undefined,
            frequency: scheduleType === WorkflowScheduleType.Cron ? frequency : '',
            scheduleType: scheduleType,
          },
        },
        {
          onSuccess: () => {
            notifications.success('Successfully scheduled workflow');
            closeAllModals();
          },
          onError: (error: any) => {
            setErrorMsg(getErrorMessageFromCode(error.response?.data?.detail?.error_code ?? ''));
            logger.error(error);
          },
        },
      );
    }
  };

  const handleTriggerTypeChange = (value: TriggerType) => {
    setTriggerType(value);
    setScheduleType(
      value === TriggerType.PERIODIC ? WorkflowScheduleType.Periodic : WorkflowScheduleType.Cron,
    );
  };

  const filteredTriggerOptions = scheduledTriggerOptions.filter(option => {
    if (
      !featureScheduledWorkflows &&
      scheduledTriggerOptions.some(scheduledOption => scheduledOption.value === option.value)
    ) {
      // Exclude scheduled trigger options if featureScheduledWorkflows is off
      return false;
    }
    if (!featureWflowsEmailTrigger && option.value === TriggerType.EMAIL) {
      // Exclude email trigger if featureWflowsEmailTrigger is off
      return false;
    }
    // Keep the rest of the options
    return true;
  });

  return (
    <Vertical h="100%">
      {errorMsg && <Alert color="red">{errorMsg}</Alert>}
      <Alert color="yellow.0">
        <Horizontal noWrap align="start">
          <IconExclamationCircleFilled color={theme.colors.yellow[7]} />
          <Text w="100%" variant="subTitle04" color="gray.7">
            Scheduling the workflow will lock it from further changes. To modify, delete the
            schedule or create a new version.
          </Text>
        </Horizontal>
      </Alert>
      <ScrollArea.Autosize offsetScrollbars>
        <Vertical h="100%" spacing="24px">
          <TriggerTypeSelect
            label="Run type"
            description="Choose the run option for this workflow to specify how the system should execute it"
            triggerOptions={filteredTriggerOptions}
            value={triggerType}
            onChange={handleTriggerTypeChange}
          />
          {triggerType === TriggerType.EMAIL && <EmailTrigger workflowId={workflowId} />}
          {scheduledTriggerOptions.some(option => option.value === triggerType) && (
            <Vertical spacing="24px">
              <DateTimePickersContainer
                startDate={startDate}
                endDate={endDate}
                handleStartDateChange={setStartDate}
                handleEndDateChange={setEndDate}
              />
              <ScheduleConfig
                triggerType={triggerType}
                setInterval={setIntervalInput}
                setFrequency={setFrequency}
              />
            </Vertical>
          )}
        </Vertical>
      </ScrollArea.Autosize>
      <Horizontal w="100%" position="right">
        <Button onClick={handleCancel} variant="light" color="gray.7" w="96px">
          Cancel
        </Button>
        <Button
          onClick={handleScheduleClick}
          variant="primary"
          color="blue.6"
          w="168px"
          loading={isScheduling || isEmailTriggerPublishing}
        >
          Schedule
        </Button>
      </Horizontal>
    </Vertical>
  );
};
