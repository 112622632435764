import { IconCategory2, IconTools, IconWand } from '@tabler/icons-react';
import { workflowEvents } from '@/main/analytics';
import { useAppMetadata } from '@/main/contexts/app-metadata/AppMetadata';
import { sendAnalytics } from '@/main/initializers/analytics';
import {
  Box,
  Button,
  Flex,
  Horizontal,
  Image,
  Text,
  Vertical,
  closeAllModals,
  openModal,
  useMarkovTheme,
} from '@/shared/design-system/v2';

const ICON_SIZE = 16;

const FreeTrialModal = (): JSX.Element => {
  const theme = useMarkovTheme();

  const { workspaceId } = useAppMetadata();

  const handleButtonClick = () => {
    sendAnalytics(workflowEvents.onboarding.freeTrailModalButtonClicked({ workspaceId }));

    closeAllModals();
  };

  const infoSections = [
    {
      title: 'Large app library',
      description: 'Access 20+ AI apps in a library that keeps growing.',
      icon: <IconWand size={ICON_SIZE} color={theme.colors.gray[7]} />,
    },
    {
      title: 'Chat with your data',
      description: 'Chat with your data and get your queries answered.',
      icon: <IconCategory2 size={ICON_SIZE} color={theme.colors.gray[7]} />,
    },
    {
      title: 'Build and deploy your own AI apps',
      description: 'Build an app from scratch or use an existing template.',
      icon: <IconTools size={ICON_SIZE} color={theme.colors.gray[7]} />,
    },
  ];

  return (
    <Vertical
      h="100%"
      p={36}
      bg="radial-gradient(46.9% 61.99% at 46.79% 104.9%, #D5E1FF 0%, #F4EDFB 45.98%, #FFFFFF 97.5%)"
    >
      <Text
        sx={{ fontWeight: 600, fontSize: '40px', lineHeight: '47px', letterSpacing: '-1px' }}
        color="gray.8"
      >
        A single platform for all your GTM needs
      </Text>
      <Horizontal h="100%" position="apart" noWrap>
        <Vertical h="100%" spacing={40} px="lg" py="md">
          <Vertical spacing="lg">
            {infoSections.map(section => (
              <Flex key={section.title} gap="sm">
                <Box pt="xs">{section.icon}</Box>
                <Box>
                  <Text variant="subTitle03" color="dark.6">
                    {section.title}
                  </Text>
                  <Text variant="bodyShort03" color="dark.3" maw={288}>
                    {section.description}
                  </Text>
                </Box>
              </Flex>
            ))}
          </Vertical>
          <Vertical align="center" spacing="sm">
            <Button w="100%" variant="primary" py="sm" onClick={handleButtonClick}>
              <Text color="white.0" variant="subTitle03">
                Start your 30-day Free Trial
              </Text>
            </Button>
            <Text variant="small01" color="purple.5">
              No credit card required.
            </Text>
          </Vertical>
        </Vertical>
        <Image
          src="https://ik.imagekit.io/markovml/homepage/illustration-trial-modal.svg?updatedAt=1738912895274"
          height={384}
          width={384}
        />
      </Horizontal>
    </Vertical>
  );
};

export const useFreeTrialModal = () => {
  const open = () =>
    openModal({
      size: 864,
      radius: 'sm',
      padding: 0,
      withCloseButton: false,
      children: <FreeTrialModal />,
    });

  return { open };
};
