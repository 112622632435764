import { Navigate, useParams } from 'react-router-dom';
import { DashboardContainer } from '@/main/components/dashboard/Dashboard.container';
import { useAbsoluteRoutes } from '@/main/router/hooks';
import { useShowSideBar } from '../home/WorkspaceShell';

const DashboardPage = () => {
  const { dashboardId } = useParams();
  const { getDataAnalyticsRoute } = useAbsoluteRoutes();

  useShowSideBar(false);

  if (!dashboardId) {
    return <Navigate to={getDataAnalyticsRoute()} />;
  }

  return <DashboardContainer dashboardId={dashboardId} />;
};

export default DashboardPage;
