import { IconArrowRight } from '@tabler/icons-react';
import { Box, Button, Text, Vertical, closeModal, openModal } from '@/shared/design-system/v2';
import { FeatureCardProps } from '../../common/empty-screen/feature/FeatureCard';
import { FeatureSection } from '../../common/empty-screen/feature/FeatureSection';
import { GRADIENT_BACKGROUND } from '../../workflows/create/templates/util';

const MODAL_ID = 'data-analytics-how-it-works-modal';

const FEATURE_CARDS: FeatureCardProps[] = [
  {
    title: 'Connect to Your Data',
    description:
      'Easily connect your tools like Spreadsheet, Snowflake, or Salesforce. No complex setup - just instant integration.',
    illustrationSrc:
      'https://ik.imagekit.io/markovml/data-analytics/connect-to-your-data.svg?updatedAt=1736417307210',
  },
  {
    title: 'Chat with Your Data',
    description:
      'Type your question in plain English and see results instantly. No SQL or waiting for support - just insights on demand.',
    illustrationSrc:
      'https://ik.imagekit.io/markovml/data-analytics/chat-with-your-data_FyneIgyZ3.svg?updatedAt=1737650810660',
  },
  {
    title: 'Visualize Your Data',
    description:
      'Transform raw data into charts and dashboards instantly. Explore trends and patterns at a glance.',
    illustrationSrc:
      'https://ik.imagekit.io/markovml/data-analytics/visualise-response-with-charts_wW6qI7qNz.svg?updatedAt=1729508023703',
  },
];

interface ActionButtonConfig {
  label: string;
  onClick: () => void;
}

interface DataAnalyticsHowItWorksModalProps {
  actionButtonConfig?: ActionButtonConfig;
}

const DataAnalyticsHowItWorksModal = ({
  actionButtonConfig,
}: DataAnalyticsHowItWorksModalProps) => {
  const actionButtonLabel = actionButtonConfig?.label ?? 'Get started';
  const actionButtonOnClick = actionButtonConfig?.onClick ?? (() => closeModal(MODAL_ID));

  return (
    <Box h="100%" bg={GRADIENT_BACKGROUND}>
      <Vertical align="center">
        <Text variant="bodyLong01">
          Ask, analyze, and visualize your data without the need for technical expertise. Get
          insights faster, easier, and smarter.
        </Text>
        <FeatureSection features={FEATURE_CARDS} />
        <Button
          variant="primary"
          rightIcon={<IconArrowRight size={20} />}
          onClick={actionButtonOnClick}
        >
          {actionButtonLabel}
        </Button>
      </Vertical>
    </Box>
  );
};

export const useDataAnalyticsHowItWorksModal = () => {
  const open = (actionbuttonConfig?: ActionButtonConfig) =>
    openModal({
      modalId: MODAL_ID,
      size: 1150,
      radius: 'sm',
      children: <DataAnalyticsHowItWorksModal actionButtonConfig={actionbuttonConfig} />,
      title: <Text variant="heading02">Turn Data Into Decisions - Instantly!</Text>,
      styles: {
        close: {
          margin: 0,
        },
        header: {
          background: GRADIENT_BACKGROUND,
          paddingBottom: 0,
        },
        title: {
          marginInline: 'auto',
        },
        body: {
          background: GRADIENT_BACKGROUND,
        },
      },
    });

  return { open };
};
