import { Navigate, RouteProps, Routes } from 'react-router-dom';
import DashboardPage from '@/main/pages/Dashboard/DashboardPage';
import { UnavailablePage } from '@/main/pages/Unavailable';
import { renderAuthRoute } from '../AuthRouter';
import { AppRoutes, getRoute } from '../constants';

const dashboardsRoutes: RouteProps[] = [
  {
    path: '/',
    element: <>Dashboard index</>,
  },
  {
    path: getRoute(AppRoutes.DASHBOARDS),
    element: <>Dashboard list page</>,
  },
  {
    path: getRoute(AppRoutes.DASHBOARD_DETAILS),
    element: <DashboardPage />,
  },
  {
    path: getRoute(AppRoutes.ERROR_ROUTE),
    element: <UnavailablePage />,
  },
  {
    path: '*',
    element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
  },
];

export const DashboardsRouter = (): JSX.Element => (
  <Routes>{dashboardsRoutes.map(renderAuthRoute)}</Routes>
);
