import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ChevronDown } from '@/shared/design-system';
import {
  Box,
  Collapse,
  Horizontal,
  Text,
  Transition,
  UnstyledButton,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { useLayoutContext } from '../../../contexts/layout/LayoutContext';
import { AppRoutes } from '../../../router/constants';
import { getActiveBar } from '../util';
import { SidebarColorScheme, useSidebarStyles } from './Sidebar.style';

interface SidebarElementProps {
  id: SideBarOptions;
  path?: AppRoutes;
  label: string;
  onClick?: () => void;
  icon: JSX.Element;
}

interface SidebarLinksProps {
  id: SideBarOptions;
  label: string;
  onClick?: () => void;
  icon: JSX.Element;
  path?: AppRoutes;
  initiallyOpened?: boolean;
  links?: SidebarElementProps[];
  colorScheme?: SidebarColorScheme;
}

export enum SideBarOptions {
  HOME,
  PROJECTS,
  SNIPPETS,
  DATASETS,
  DATA_ANALYTICS,
  DATA_SOURCES,
  DATA_LABELING,
  WORKFLOWS,
  WORKFLOWS_TEMPLATES,
  WORKFLOWS_HOME,
  WORKFLOWS_LIST,
  WORKFLOWS_APPS,
  WORKFLOWS_ARCHIVED,
  VIEW_MORE,
  MODELS,
  MODEL_APPS,
  APP_STORE,
  EXPERIMENTS,
  EVALUATION,
  INVITE_MEMBERS,
  DOCS_AND_SUPPORT,
  WHATS_NEW,
  HELP_DOCS,
  SETTINGS,
  APP_BUILDER,
  AI_APPS,
}

export const SidebarLinks = ({
  id,
  path,
  label,
  onClick,
  icon,
  initiallyOpened,
  links,
  colorScheme,
}: SidebarLinksProps): JSX.Element => {
  const theme = useMarkovTheme();
  const { classes, cx } = useSidebarStyles({ colorScheme });
  const { sidebarExpanded, sidebarMouseEnter } = useLayoutContext();
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);

  const { pathname } = useLocation();

  const activeTab = useMemo(() => getActiveBar(pathname), [pathname]);

  const containsActiveTab = (): boolean => {
    if (links) {
      return links.some(link => link.id === activeTab);
    }
    return false;
  };

  useEffect(() => {
    if (sidebarMouseEnter) {
      containsActiveTab() ? setOpened(true) : setOpened(false);
    }
  }, [sidebarMouseEnter]);

  return (
    <>
      <UnstyledButton
        my="sm"
        component={Horizontal}
        noWrap
        key={label}
        onClick={hasLinks ? () => setOpened(!opened) : onClick}
        className={cx(classes.navButton, {
          [classes.active]: activeTab === id,
        })}
        data-testid={`sidebar-link-${label}`} // Add data-testid here
      >
        <Box w={24} h={24}>
          {icon}
        </Box>
        <Transition
          mounted={sidebarMouseEnter}
          transition="slide-right"
          duration={100}
          timingFunction="ease"
        >
          {styles => (
            <Text variant="subTitle03" truncate style={styles}>
              {label}
            </Text>
          )}
        </Transition>
        {hasLinks && (
          <ChevronDown
            style={{
              marginLeft: '72px',
              height: '12px',
              width: '12px',
              transform: opened ? 'rotate(-180deg)' : 'none',
            }}
          />
        )}
      </UnstyledButton>
      {hasLinks ? (
        <Collapse in={opened}>
          {links.map(link => (
            <UnstyledButton
              pl="xl"
              component={Horizontal}
              noWrap
              key={link.label}
              onClick={link.onClick}
              className={cx(classes.navButton, {
                [classes.active]: activeTab === link.id && sidebarMouseEnter,
              })}
              data-testid={`sidebar-link-${link.label}`} // Add data-testid here
            >
              <Box
                sx={{
                  display: sidebarExpanded ? 'block' : 'none',

                  [`@media (min-width: ${theme.breakpoints.sm})`]: {
                    display: sidebarMouseEnter ? 'block' : 'none',
                  },
                }}
              >
                <Text variant="bodyShort02" truncate>
                  {link.label}
                </Text>
              </Box>
            </UnstyledButton>
          ))}
        </Collapse>
      ) : null}
    </>
  );
};
