import { closeAllModals, openModal } from '@mantine/modals';
import { useNavigate } from 'react-router-dom';
import { sendAnalytics } from '@/main/initializers/analytics';
import {
  Alert,
  Button,
  Center,
  Horizontal,
  Loader,
  Text,
  TextInput,
  Vertical,
  notifications,
  useInputState,
} from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';
import { workflowEvents } from '../../../../analytics';
import { useAppMetadata } from '../../../../contexts/app-metadata/AppMetadata';
import { useGetWorkflowDetailsQuery } from '../../../../queries/workflows/detail/detail';
import { useWorkflowCloneMutation } from '../../../../queries/workflows/list/list';
import { useAbsoluteRoutes } from '../../../../router/hooks';

export interface CloneWorkflowInputModalProps {
  currentWorkflowName: string;
  workflowId: string;
}
const HEADING = 'Cloning the workflow';
const SUBHEADING = `You can clone the workflow to create an exact replica, including the source file, operator configurations, and sink destination.`;

const CloneWorkflowInputModal = ({
  currentWorkflowName,
  workflowId,
}: CloneWorkflowInputModalProps): JSX.Element => {
  const { workspaceId } = useAppMetadata();
  const navigate = useNavigate();
  const { getWorkflowBuilderRoute } = useAbsoluteRoutes();
  const { mutateAsync: cloneWorkflow, isLoading: isCloning } = useWorkflowCloneMutation();
  const [workflowName, setWorkflowName] = useInputState(`${currentWorkflowName}` + ' (Copy)');

  const displayState = 'Clone';
  const displayName = 'workflow';

  const onConfirmCloning = async () => {
    sendAnalytics(workflowEvents.create.clone({ workspaceId, sourceWorkflowId: workflowId }));
    try {
      const data = await cloneWorkflow({ workflowId, workflowName });
      navigate(getWorkflowBuilderRoute(data.data.workflowId));
      notifications.success(`${displayState}d selected ${displayName}`);
      closeAllModals();
    } catch (error) {
      notifications.error(`Unable to ${displayState} selected ${displayName}`);
      logger.error(error);
    }
  };
  const handleCancel = () => {
    closeAllModals();
  };

  return (
    <Vertical spacing="sm">
      <Text variant="bodyShort02">{SUBHEADING}</Text>
      <TextInput
        autoFocus
        ariaLabel="Name the new workflow"
        label="Name the new workflow"
        placeholder="New workflow name"
        value={workflowName}
        onChange={setWorkflowName}
      />
      <Horizontal w="100%" position="right" pt="xl">
        <Button onClick={handleCancel} variant="light" color="gray.7" bg="gray.1">
          Cancel
        </Button>
        <Button onClick={onConfirmCloning} loading={isCloning} variant="primary" color="blue">
          Clone
        </Button>
      </Horizontal>
    </Vertical>
  );
};

interface CloneWorkflowInputModalContainerProps {
  workflowId: string;
}

const CloneWorkflowInputModalContainer = ({
  workflowId,
}: CloneWorkflowInputModalContainerProps) => {
  const { isLoading, isError, data: workflowDetails } = useGetWorkflowDetailsQuery(workflowId);

  if (isLoading || !workflowDetails) {
    return (
      <Center h={200} w={250}>
        <Loader text="loading..." />
      </Center>
    );
  }

  if (isError) {
    return (
      <Center h={200} w={250}>
        <Alert color="red">Unable to load workflow details. Please try again.</Alert>
      </Center>
    );
  }

  return (
    <CloneWorkflowInputModal currentWorkflowName={workflowDetails?.name} workflowId={workflowId} />
  );
};

export const useWorkflowClone = (workflowId: string) => {
  const openCloneModal = () =>
    openModal({
      title: <Text variant="heading03">{HEADING}</Text>,
      children: <CloneWorkflowInputModalContainer workflowId={workflowId} />,
    });

  return { openCloneModal };
};
