const freshWorksToken = import.meta.env.VITE_APP_FRESHWORKS_TOKEN ?? '';
declare global {
  interface Window {
    fcWidget: any;
  }
}

export const initFreshChat = () => {
  const existingScript = document.querySelector(`script[src="${freshWorksToken}"]`);
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = freshWorksToken;
    script.setAttribute('chat', 'true');
    script.async = true;
    document.body.appendChild(script);
  } else {
    if (window.fcWidget) window.fcWidget.show();
  }
};

export const hideFreshChat = () => {
  const existingScript = document.querySelector(`script[src="${freshWorksToken}"]`);
  if (existingScript && window.fcWidget) {
    window.fcWidget.hide();
  }
};

export const identifyFreshchatUser = (userId: string, name: string, email: string) => {
  if (window.fcWidget) {
    window.fcWidget.setExternalId(userId);
    window.fcWidget.user.setFirstName(name);
    window.fcWidget.user.setEmail(email);
  }
};
