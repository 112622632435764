import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';

export const toCamelCase = (data: any): any => {
  if (Array.isArray(data)) {
    return data.map(item => {
      if (item !== null && typeof item === 'object' && !Array.isArray(item)) {
        return mapKeys(item, (_value, key) => camelCase(key));
      }
      return item;
    });
  }
  return data;
};
