import { useMutation, useQuery } from '@tanstack/react-query';
import { DashboardModel } from '@/main/components/dashboard/util';

const dashboards: DashboardModel[] = [
  {
    dashboardId: '01JKT6XZC8MCGV3ZM12265Z67F',
    name: 'Ad campaign analysis',
    layout: [
      { i: '01JKTKY0ERRKVG6BYTWBFKMCG9', x: 0, y: 0, w: 8, h: 8 },
      { i: '01JKTKYG2HP07XPK8PH4YFTY8V', x: 0, y: 10, w: 12, h: 12, minW: 2, maxW: 4 },
      { i: '01JKTKYSNKV2HEJTR7DZQTSHJT', x: 10, y: 0, w: 16, h: 10 },
    ],
  },
  {
    dashboardId: '01JKT6XZC8MCGV3ZM12265Z67G',
    name: 'Sales dashboard',
    layout: [
      { i: '01JKTKZ0TQ8EH2YNS1PJS38GE6', x: 0, y: 0, w: 1, h: 2, static: true },
      { i: '01JKTKZ61YFGMB261RT669VT0N', x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4 },
      { i: '01JKTKZBS2Y4A1QGJP1N8VYXXQ', x: 4, y: 0, w: 1, h: 2 },
    ],
  },
  {
    dashboardId: '01JKT6XZC8MCGV3ZM12265Z67H',
    name: 'Marketing dashboard',
    layout: [
      { i: '01JKTKZMPMX9G9Y4NA35GF4T80', x: 0, y: 0, w: 1, h: 2, static: true },
      { i: '01JKTKZWAFD9JW854NDXRRSFKF', x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4 },
      { i: '01JKTM01GP316PTCW39ZTDGW61', x: 4, y: 0, w: 1, h: 2 },
    ],
  },
];

export const useGetDashboardQuery = (dashboardId: string) =>
  useQuery({
    queryKey: ['dashboard', dashboardId],
    queryFn: async () =>
      Promise.resolve(
        dashboards.find(dashboard => dashboard.dashboardId === dashboardId) as DashboardModel,
      ),
    select: data => data,
  });

export const useUpdateDashboardMutation = () =>
  useMutation({
    mutationFn: async (dashboard: DashboardModel) => {
      const index = dashboards.findIndex(d => d.dashboardId === dashboard.dashboardId);
      dashboards[index] = dashboard;
      return Promise.resolve({
        ack: true,
        dashboardId: dashboard.dashboardId,
      });
    },
  });
