import { IconBook, IconCircleCaretRight, IconSearch } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { MARKOVML_DOCS } from '@/main/developer-docs/links';
import { useGetUserInfoQuery } from '@/main/queries/account/workspace';
import { useAbsoluteRoutes } from '@/main/router/hooks';
import {
  Box,
  Button,
  Container,
  Horizontal,
  Image,
  Text,
  UnstyledButton,
  Vertical,
} from '@/shared/design-system/v2';
import { AppFeatureCard } from './AppFeatureCard';

const HERO_ILLUSTRATION =
  'https://ik.imagekit.io/markovml/homepage/multi-ui-preview_TE-BmXxgI.svg?updatedAt=1739416414171';
const CHAT_WITH_DATA_ILLUSTRATION =
  'https://ik.imagekit.io/markovml/homepage/chat-with-data_Xth2o7I4S.svg?updatedAt=1739418202749';
const AI_APPS_ILLUSTRATION =
  'https://ik.imagekit.io/markovml/homepage/ai-apps_vVoYwD-Pr.svg?updatedAt=1739418198541';
const AUTOMATIONS_ILLUSTRATION =
  'https://ik.imagekit.io/markovml/homepage/automation-flow_OlHTbQfGT.svg?updatedAt=1739463426554';

export const GlobalHome = (): JSX.Element => {
  const navigate = useNavigate();
  const { getDataAnalyticsRoute, getWorkflowsAppsRoute, getWorkflowsHomeRoute } =
    useAbsoluteRoutes();

  const { data: user } = useGetUserInfoQuery();

  const handleNavigateToDataAnalytics = () => {
    navigate(getDataAnalyticsRoute());
  };

  const handleNavigateToApps = () => {
    navigate(getWorkflowsAppsRoute());
  };

  const handleNavigateToAutomations = () => {
    navigate(getWorkflowsHomeRoute());
  };

  const handleNavigateVideoTutorials = () => {
    window.open('https://www.youtube.com/@markovml5575');
  };

  const handleNavigateDocumentation = () => {
    window.open(`${MARKOVML_DOCS}/docs/welcome-to-markovml-docs `);
  };

  const handleNavigateFAQs = () => {
    window.open(`${MARKOVML_DOCS}/docs/welcome-to-markovml-docs `);
  };

  const features = [
    {
      title: 'Chat with your data',
      description:
        'Unload or integrate your dataset. Execute SQl queries or chat in plain English to get actionable insights for data-driven decision-making.',
      ctaLabel: 'Explore Data Insights',
      illustrationUrl: CHAT_WITH_DATA_ILLUSTRATION,
      onClick: handleNavigateToDataAnalytics,
    },
    {
      title: 'Get Things Done with AI Apps',
      description:
        'Discover Al apps that streamline tasks, deliver results instantly, and drive impact. Accelerate your GTM campaigns while AI handles the heavy lifting.',
      ctaLabel: 'Discover AI Apps',
      illustrationUrl: AI_APPS_ILLUSTRATION,
      onClick: handleNavigateToApps,
    },
    {
      title: 'Automation flows',
      description:
        'Select an automation template, add your data source and hit run to get results in minutes. Fast-forward your GTM campaigns while AI does the heavy lifting.',
      ctaLabel: 'Explore Flow Automation',
      illustrationUrl: AUTOMATIONS_ILLUSTRATION,
      onClick: handleNavigateToAutomations,
    },
  ];

  const userFirstName = user?.name?.split(' ')?.at(0) ?? '';
  const heroMessage = userFirstName ? `Welcome aboard, ${userFirstName}` : 'Welcome aboard';

  return (
    <Box bg="white.0" mih="100%">
      <Container size="md" py={32}>
        <Vertical spacing="xxl">
          <Vertical
            p="xxl"
            pb={32}
            bg="linear-gradient(90deg, rgba(233, 239, 255, 0.6) 0%, rgba(244, 237, 251, 0.6) 100%)"
            sx={theme => ({
              border: `1px solid {theme.colors.blue[0]}`,
              borderRadius: theme.radius.sm,
            })}
          >
            <Horizontal noWrap spacing={66} align="start">
              <Box pt="lg" pl="xxl">
                <Vertical spacing="xs" mih={170}>
                  <Text variant="heading02">{heroMessage}</Text>
                  <Text variant="bodyShort01" color="gray.8">
                    Effortlessly fast-forward your pipelines by automating your GTM tasks. Analyze
                    data, visualize charts, and share reports instantly.
                  </Text>
                </Vertical>
                <Vertical spacing="sm">
                  <Text variant="subTitle03" color="gray.7">
                    Start your MarkovML journey!
                  </Text>
                  <Horizontal spacing="lg">
                    <Button variant="primary" onClick={handleNavigateToDataAnalytics}>
                      <Text variant="subTitle04" color="white.0">
                        Get Insights from Data
                      </Text>
                    </Button>
                    <UnstyledButton onClick={handleNavigateToApps}>
                      <Text variant="subTitle03" color="blue.7" sx={{ cursor: 'pointer' }}>
                        or Discover AI Apps
                      </Text>
                    </UnstyledButton>
                  </Horizontal>
                </Vertical>
              </Box>
              <Box w={500} sx={{ flexShrink: 0 }}>
                <Image src={HERO_ILLUSTRATION} w="100%" />
                <Vertical>
                  <Text variant="overline" color="gray.6" tt="uppercase">
                    Your Quick Start Guide
                  </Text>
                  <Horizontal spacing="xs">
                    {/* TODO: Add links for these buttons */}
                    <Button
                      color="white"
                      leftIcon={<IconCircleCaretRight size={20} />}
                      onClick={handleNavigateVideoTutorials}
                    >
                      Video Tutorials
                    </Button>
                    <Button
                      color="white"
                      leftIcon={<IconBook size={20} />}
                      onClick={handleNavigateDocumentation}
                    >
                      Documentation
                    </Button>
                    <Button
                      color="white"
                      leftIcon={<IconSearch size={20} />}
                      onClick={handleNavigateFAQs}
                    >
                      FAQs
                    </Button>
                  </Horizontal>
                </Vertical>
              </Box>
            </Horizontal>
          </Vertical>
          <Text variant="bodyLong02" color="gray.6">
            Explore our offerings, use cases, and more.
          </Text>
          <Vertical spacing="xxl">
            {features.map(({ title, description, ctaLabel, illustrationUrl, onClick }, i) => (
              <AppFeatureCard
                key={i}
                title={title}
                description={description}
                ctaLabel={ctaLabel}
                illustrationUrl={illustrationUrl}
                onClick={onClick}
              />
            ))}
          </Vertical>
        </Vertical>
      </Container>
    </Box>
  );
};
