import {
  IconExclamationMark,
  IconEye,
  IconInfoCircleFilled,
  IconPencil,
  IconServerCog,
  IconTrashX,
} from '@tabler/icons-react';
import first from 'lodash/first';
import get from 'lodash/get';
import { PropsWithChildren, useMemo, useState } from 'react';
import {
  Handle,
  HandleType,
  NodeProps,
  Position,
  ReactFlowState,
  getConnectedEdges,
  useNodeId,
  useStore,
} from 'reactflow';
import { sendAnalytics } from '@/main/initializers/analytics';
import { useGetWorkflowRunCreditUsageQuery } from '@/main/queries/workflows/runs/runs';
import {
  ActionIcon,
  Box,
  Center,
  Flex,
  Horizontal,
  HoverCard,
  Text,
  Tooltip,
  Vertical,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { workflowEvents } from '../../../analytics';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { useCreateWorkflow } from '../../../contexts/workflows/CreateWorkflow';
import { useWorkflowRunContext } from '../../../contexts/workflows/WorkflowRunContext';
import {
  ErrorObject,
  OperatorCategory,
  OperatorIODescription,
  OperatorModel,
  WorkflowRunOperatorStatus,
} from '../../../generated/api';
import { useDebugRunStatusQuery } from '../../../queries/workflows/debug';
import {
  useGetOperatorDetailsQuery,
  useGetWorkflowDagNodesSchemaV2Query,
} from '../../../queries/workflows/operators';
import { OperatorIcon } from '../../workspace/studio-home/workflow/OperatorIcon';
import { useLogsModal } from '../create/debug-panel/logs-modal/use-logs-modal';
import { findSourceNodes } from '../create/utils';
import {
  FormModal,
  ReadOnlyFormModal,
} from '../create/workflow-builder/operator-parameter-form/FormModal';
import { WorkflowCreditInformation } from '../detail/runs/util';
import { OperatorDescriptionCard } from '../list/operators/OperatorDescriptionCard';
import { ResourceName } from './ResourceName';
import {
  ICON_SIZE,
  MAX_ALLOWED_CONNECTIONS_PER_HANDLE,
  NODE_BORDER,
  NODE_HEIGHT,
  NODE_REVOLVING_BORDER,
  NODE_WIDTH,
  NodeData,
  getErrorMessageFromErrorCode,
  transformNodeToWorkflowNode,
  useGetColorsFromStatus,
  useNodeLoadingBorder,
} from './utils';

interface InputOutputHandleProps {
  position: Position;
  handleType: HandleType;
  io: OperatorIODescription[];
}

interface NodeActionIconProps {
  onClickAction: () => void;
  icon: JSX.Element;
  tooltip: string;
}

interface OperatorHoverCardProps {
  iconUrl: string;
  title: string;
  description: string;
  example: string;
  creditInfo: string;
}

export const OperatorHoverCard = ({
  iconUrl,
  title,
  description,
  example,
  creditInfo,
}: OperatorHoverCardProps) => {
  const theme = useMarkovTheme();

  return (
    <HoverCard position="right" withinPortal>
      <HoverCard.Target>
        <IconInfoCircleFilled color={theme.colors.gray[5]} />
      </HoverCard.Target>
      <HoverCard.Dropdown p={0} sx={{ borderRadius: '8px', borderColor: theme.colors.dark[4] }}>
        <OperatorDescriptionCard
          iconUrl={iconUrl}
          title={title}
          description={description}
          example={example}
          creditInfo={creditInfo}
        />
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

const selector = (s: ReactFlowState) => ({
  nodeInternals: s.nodeInternals,
  edges: s.edges,
});

const InputOutputHandle = ({ position, handleType, io }: InputOutputHandleProps) => {
  const { nodeInternals, edges } = useStore(selector);
  const nodeId = useNodeId();

  const isHandleConnectable = useMemo(() => {
    if (!nodeId) return false;
    const node = nodeInternals.get(nodeId);
    if (!node) return false;
    return getConnectedEdges([node], edges).length < MAX_ALLOWED_CONNECTIONS_PER_HANDLE;
  }, [nodeInternals, edges, nodeId]);

  const handleHeight = 12;
  const spacing = 64;
  const handles = io.map((item, index) => {
    const offset = (index - (io.length - 1) / 2) * (handleHeight + spacing);
    const topPosition = NODE_WIDTH / 2 + offset;
    return (
      <Handle
        key={item.id}
        id={item.id}
        type={handleType}
        position={position}
        isConnectable={isHandleConnectable}
        style={{
          [position === Position.Top ? 'top' : 'bottom']: '-6px',
          backgroundColor: '#ADB5BD',
          height: `${handleHeight}px`,
          width: `${handleHeight}px`,
          left: `${topPosition}px`,
        }}
      />
    );
  });

  return <>{handles}</>;
};

const InputHandle = ({ io }: { io: OperatorIODescription[] }) => (
  <InputOutputHandle position={Position.Top} handleType="target" io={io} />
);

const OutputHandle = ({ io }: { io: OperatorIODescription[] }) => (
  <InputOutputHandle position={Position.Bottom} handleType="source" io={io} />
);

const NodeActionIcon = ({ onClickAction, icon, tooltip }: NodeActionIconProps) => (
  <Tooltip withArrow label={tooltip}>
    <ActionIcon onClick={onClickAction}>
      <Box
        p="xs"
        bg="white.0"
        sx={{
          borderRadius: '50%',
          boxShadow:
            '0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -5px rgba(0, 0, 0, 0.05), 0px 7px 7px -5px rgba(0, 0, 0, 0.04);',
        }}
      >
        {icon}
      </Box>
    </ActionIcon>
  </Tooltip>
);

const NodeContainer = ({
  bgColor,
  borderColor,
  isLoading,
  revolvingBorder,
  nodeErrors,
  shape = 'circle',
  children,
}: PropsWithChildren<{
  bgColor: string;
  borderColor: string;
  isLoading?: boolean;
  revolvingBorder?: string;
  nodeErrors?: ErrorObject[];
  shape?: 'circle' | 'rectangle';
}>) => {
  const theme = useMarkovTheme();
  const borderRadius = shape === 'circle' ? '50%' : '8px';
  const nodeBorderClass = useNodeLoadingBorder();
  const borderStyle =
    revolvingBorder || isLoading
      ? `none`
      : nodeErrors?.length
      ? `${NODE_BORDER}px solid ${theme.colors.orange[3]}`
      : `${NODE_BORDER}px solid ${borderColor}`;

  return (
    <>
      <Center className={isLoading ? nodeBorderClass : revolvingBorder}>
        <Box
          p="lg"
          pos="relative"
          bg={bgColor}
          w={
            revolvingBorder || isLoading
              ? NODE_WIDTH - NODE_REVOLVING_BORDER + NODE_BORDER
              : NODE_WIDTH
          }
          h={
            revolvingBorder || isLoading
              ? NODE_HEIGHT - NODE_REVOLVING_BORDER + NODE_BORDER
              : NODE_HEIGHT
          }
          className="node-component"
          sx={{
            border: borderStyle,
            borderRadius,
            boxShadow:
              '0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -5px rgba(0, 0, 0, 0.05), 0px 7px 7px -5px rgba(0, 0, 0, 0.04);',
          }}
        >
          {children}
        </Box>
      </Center>
      {(nodeErrors ?? []).length > 0 && (
        <HoverCard position="bottom-start" radius="sm" withinPortal>
          <HoverCard.Target>
            <Center
              pos="absolute"
              p="xs"
              top={shape === 'circle' ? NODE_HEIGHT - 28 : NODE_HEIGHT - 16}
              left={NODE_WIDTH - 40}
              bg="red.6"
              sx={{
                border: `3px solid ${theme.colors.orange[3]}`,
                borderRadius: '50%',
              }}
            >
              <IconExclamationMark size={20} color={theme.colors.white[0]} />
            </Center>
          </HoverCard.Target>
          <HoverCard.Dropdown
            px="lg"
            py="sm"
            maw={300}
            sx={{
              borderColor: theme.colors.orange[4],
              backgroundColor: theme.colors.orange[4],
            }}
          >
            <Vertical>
              {nodeErrors?.map(error => (
                <Text variant="small03" color="white.0" key={error.errorCode}>
                  {getErrorMessageFromErrorCode(error.errorCode)}
                </Text>
              ))}
            </Vertical>
          </HoverCard.Dropdown>
        </HoverCard>
      )}
    </>
  );
};

const NodeComponent = ({
  id,
  data,
  shape,
  category,
}: {
  id: string;
  data: NodeData;
  shape: 'circle' | 'rectangle';
  category: OperatorCategory;
}) => {
  const { workspaceId } = useAppMetadata();
  const theme = useMarkovTheme();
  const { workflowId, onNodeDelete, mode } = useCreateWorkflow();
  const { runId } = useWorkflowRunContext();
  const [isOpen, setIsOpen] = useState(false);

  const { data: operator, isLoading } = useGetOperatorDetailsQuery(data.id);
  const {
    data: dagNodeSchema,
    isLoading: isDagNodeSchemaLoading,
    isFetching,
  } = useGetWorkflowDagNodesSchemaV2Query(workflowId, id);
  const { data: debugRunStatus } = useDebugRunStatusQuery(workflowId, runId);

  const ioSchema = dagNodeSchema?.nodeSchemas;
  const nodeErrors = ioSchema?.[id]?.errorsWithCode;
  const operatorStatus = debugRunStatus?.operatorsStatus?.find(status => status.nodeId === id)
    ?.statusDetails.status;

  const { bgColor, borderColor, revolvingBorder } = useGetColorsFromStatus(
    operatorStatus ?? WorkflowRunOperatorStatus.NotStarted,
    category,
  );

  const resourceId = get(data.configuration, 'resource_id');
  const fileName = get(data.configuration, 'file_name');
  const identifier = get(data.configuration, 'identifier');

  const handleNodeClick = () => {
    if (!isLoading) {
      sendAnalytics(
        workflowEvents.dag.operatorNodeClicked({ operatorId: data.id, workflowId, workspaceId }),
      );
      setIsOpen(true);
    }
  };

  const handleEditClick = () => {
    if (!isLoading) {
      sendAnalytics(
        workflowEvents.dag.editNodeClicked({
          nodeId: id,
          operatorId: data.id,
          workflowId,
          workspaceId,
        }),
      );
      setIsOpen(true);
    }
  };

  const handleDelete = () => {
    sendAnalytics(
      workflowEvents.dag.deleteNodeClicked({
        nodeId: id,
        operatorId: data.id,
        workflowId,
        workspaceId,
      }),
    );
    onNodeDelete(id);
  };

  const handleFormClose = () => setIsOpen(false);

  const isOperatorLoading = isDagNodeSchemaLoading || isFetching || isLoading;

  return (
    <Horizontal
      noWrap
      spacing="md"
      align="center"
      sx={{
        '&:hover': {
          ['.action-icon']: {
            visibility: 'visible',
          },
          ['.node-component']: {
            border:
              revolvingBorder || isOperatorLoading
                ? `none`
                : nodeErrors?.length
                ? `${NODE_BORDER}px solid ${theme.colors.orange[3]}`
                : `${NODE_BORDER}px solid #82CBF9`,
          },
        },
      }}
    >
      <Box onClick={handleNodeClick} data-testid={`operator-${operator?.operatorId}`}>
        <NodeContainer
          bgColor={bgColor}
          borderColor={borderColor}
          shape={shape}
          revolvingBorder={revolvingBorder}
          isLoading={isOperatorLoading}
          nodeErrors={nodeErrors}
        >
          <Horizontal h="100%" w="100%" noWrap>
            <OperatorIcon iconUrl={operator?.iconUrl ?? ''} size={ICON_SIZE} />
            <Vertical spacing="xs">
              <Text variant="subTitle02" color="gray.7" title={operator?.name}>
                {operator?.name}
              </Text>
              {(resourceId || fileName || identifier) && (
                <ResourceName
                  operator={operator}
                  resourceId={resourceId as string}
                  fileName={fileName as string}
                  identifier={identifier as string}
                />
              )}
            </Vertical>
          </Horizontal>
        </NodeContainer>
      </Box>
      <InputHandle io={operator?.inputs || []} />
      <OutputHandle io={operator?.outputs || []} />

      {!isLoading && operator && (
        <Horizontal
          noWrap
          spacing="sm"
          className="action-icon"
          sx={{ visibility: 'hidden', zIndex: 100 }}
        >
          <NodeActionIcon
            onClickAction={handleEditClick}
            tooltip="Edit"
            icon={<IconPencil color={theme.colors.gray[7]} width={20} height={20} />}
          />
          <NodeActionIcon
            onClickAction={handleDelete}
            tooltip="Remove"
            icon={<IconTrashX color={theme.colors.gray[7]} width={20} height={20} />}
          />
          <OperatorHoverCard
            iconUrl={operator.iconUrl}
            title={operator.name}
            description={operator.description}
            example={operator.example}
            creditInfo={operator.creditRateString ?? ''}
          />
        </Horizontal>
      )}
      <FormModal
        id={id}
        isOpen={isOpen}
        handleFormClose={handleFormClose}
        operatorModel={operator}
        configuration={data.configuration}
      />
    </Horizontal>
  );
};

const NodeViewComponent = ({
  id,
  data,
  shape,
  operatorList,
  category,
  renderNodeActions,
  isPublicTemplate,
}: {
  id: string;
  data: NodeData;
  shape: 'circle' | 'rectangle';
  operatorList: OperatorModel[];
  category: OperatorCategory;
  renderNodeActions?: boolean;
  isPublicTemplate?: boolean;
}) => {
  const theme = useMarkovTheme();
  const { workflowId, runId, isDebugMode } = useWorkflowRunContext();
  const { workspaceId } = useAppMetadata();
  const openLogModal = useLogsModal();

  const operator = operatorList.find(operator => operator.operatorId === data.id);

  const [isOpen, setIsOpen] = useState(false);

  const { data: runStatus } = useDebugRunStatusQuery(workflowId, runId);

  const runDag = runStatus?.dag;

  const { data: creditDetails } = useGetWorkflowRunCreditUsageQuery(
    workflowId,
    runId,
    runStatus?.wflRunStatus,
  );
  const { bgColor, borderColor, revolvingBorder } = useGetColorsFromStatus(
    runStatus?.operatorsStatus?.find(status => status.nodeId === id)?.statusDetails.status ??
      WorkflowRunOperatorStatus.NotStarted,
    category,
  );

  const operatorStatus = runStatus?.operatorsStatus?.find(status => status.nodeId === id);

  const operatorCreditUsage = creditDetails?.operatorCreditConsumedDetails.find(
    operatorsCredits => operatorsCredits.nodeId === id,
  );

  const resourceId = get(data.configuration, 'resource_id');
  const fileName = get(data.configuration, 'file_name');
  const identifier = get(data.configuration, 'identifier');

  const handleViewClick = () => {
    sendAnalytics(
      workflowEvents.runs.workflowRunsOperatorViewConfigClicked({
        workflowId: workflowId,
        workspaceId: workspaceId,
        operatorId: data.id,
        runId: runId,
      }),
    );
    if (!renderNodeActions) {
      setIsOpen(true);
    }
  };

  const handleLogsClick = () => {
    sendAnalytics(
      workflowEvents.runs.workflowRunsNodeLogsTabClicked({
        workflowId: workflowId,
        workspaceId: workspaceId,
        operatorId: data.id,
        runId: runId,
      }),
    );
    if (!renderNodeActions && operatorStatus && runDag) {
      openLogModal(
        workflowId,
        runId,
        'logs',
        id,
        operatorStatus.statusDetails,
        first(
          findSourceNodes(
            {
              nodes: runDag.nodes.map(node => transformNodeToWorkflowNode(node, operatorList)),
              edges: runDag.edges,
            },
            id,
          ),
        ),
      );
    }
  };

  const handleFormClose = () => {
    sendAnalytics(
      workflowEvents.runs.workflowsRunsNodeLogsModalClosed({
        workflowId: workflowId,
        workspaceId: workspaceId,
        runId: runId,
      }),
    );
    setIsOpen(false);
  };

  return (
    <Horizontal
      align="center"
      spacing="xs"
      noWrap
      sx={{
        '&:hover': {
          ['.action-icon']: {
            visibility: 'visible',
          },
          ['.node-component']: {
            border:
              runId.length === 0
                ? `${NODE_BORDER}px solid #82CBF9`
                : revolvingBorder
                ? `none`
                : `${NODE_BORDER}px solid ${borderColor}`,
          },
        },
      }}
    >
      <Box onClick={handleViewClick} data-testid={`operator-${operator?.operatorId}`}>
        <NodeContainer
          bgColor={bgColor}
          borderColor={borderColor}
          shape={shape}
          revolvingBorder={revolvingBorder}
        >
          <Flex h="100%" w="100%">
            <Horizontal h="100%" w="100%" noWrap>
              <OperatorIcon iconUrl={operator?.iconUrl ?? ''} size={ICON_SIZE} />
              <Vertical spacing="xs">
                <Text variant="subTitle02" color="gray.7" title={operator?.name}>
                  {operator?.name}
                </Text>
                {!isPublicTemplate && (resourceId || fileName || identifier) && (
                  <ResourceName
                    operator={operator}
                    resourceId={resourceId as string}
                    fileName={fileName as string}
                    identifier={identifier as string}
                  />
                )}
              </Vertical>
            </Horizontal>
            {operatorCreditUsage && (
              <WorkflowCreditInformation credits={operatorCreditUsage.creditsConsumed} />
            )}
          </Flex>
        </NodeContainer>
      </Box>
      <InputHandle io={operator?.inputs || []} />
      <OutputHandle io={operator?.outputs || []} />

      {!renderNodeActions && operator && (
        <Horizontal
          noWrap
          spacing="sm"
          className="action-icon"
          sx={{ visibility: 'hidden', zIndex: 12 }}
        >
          <NodeActionIcon
            onClickAction={handleViewClick}
            tooltip="View config"
            icon={<IconEye color={theme.colors.gray[7]} width={24} height={24} />}
          />
          {Boolean(operatorStatus && runDag) && (
            <NodeActionIcon
              onClickAction={handleLogsClick}
              tooltip="View server logs"
              icon={<IconServerCog color={theme.colors.gray[7]} width={24} height={24} />}
            />
          )}
          <OperatorHoverCard
            iconUrl={operator.iconUrl}
            title={operator.name}
            description={operator.description}
            example={operator.example}
            creditInfo={operator.creditRateString ?? ''}
          />
        </Horizontal>
      )}
      <ReadOnlyFormModal
        id={id}
        workflowId={workflowId}
        isOpen={isOpen}
        handleClose={handleFormClose}
        operatorModel={operator}
        configuration={data.configuration}
        isDebugMode={isDebugMode}
      />
    </Horizontal>
  );
};

export const SourceOperator = (props: NodeProps<NodeData>) => (
  <NodeComponent {...props} shape="rectangle" category={OperatorCategory.Source} />
);

export const ProcessView = (props: NodeProps<NodeData>) => (
  <NodeComponent {...props} shape="rectangle" category={OperatorCategory.Process} />
);

export const DestinationOperator = (props: NodeProps<NodeData>) => (
  <NodeComponent {...props} shape="rectangle" category={OperatorCategory.Sink} />
);

export const getNodeViewerTypes = (
  operatorsList: OperatorModel[],
  renderNodeActions: boolean,
  isPublicTemplate: boolean,
) => ({
  [OperatorCategory.Source]: (props: NodeProps<NodeData>) => (
    <NodeViewComponent
      {...props}
      shape="rectangle"
      operatorList={operatorsList}
      category={OperatorCategory.Source}
      renderNodeActions={renderNodeActions}
      isPublicTemplate={isPublicTemplate}
    />
  ),
  [OperatorCategory.Sink]: (props: NodeProps<NodeData>) => (
    <NodeViewComponent
      {...props}
      shape="rectangle"
      operatorList={operatorsList}
      category={OperatorCategory.Sink}
      renderNodeActions={renderNodeActions}
      isPublicTemplate={isPublicTemplate}
    />
  ),
  [OperatorCategory.Process]: (props: NodeProps<NodeData>) => (
    <NodeViewComponent
      {...props}
      shape="rectangle"
      operatorList={operatorsList}
      category={OperatorCategory.Process}
      renderNodeActions={renderNodeActions}
      isPublicTemplate={isPublicTemplate}
    />
  ),
});

export const nodeTypes = {
  [OperatorCategory.Source]: SourceOperator,
  [OperatorCategory.Sink]: DestinationOperator,
  [OperatorCategory.Process]: ProcessView,
};
