import { useMediaQuery } from '@mantine/hooks';
import { MantineSize } from '@mantine/styles';
import { useLocation } from 'react-router-dom';
import { useMarkovTheme } from '@/shared/design-system/v2';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { useLayoutContext } from '../../contexts/layout/LayoutContext';
import { AppRoutes, getIsUserProfile, getRoute } from '../../router/constants';
import { SideBarOptions } from './sidebar-v2/SidebarLinks';
import { NAV_BAR_EXPANDED_SIZE, getFeatureFamilySidebar } from './sidebar-v3/Sidebar';

export const NAV_BAR_COLLAPSED_SIZE = 62;
export interface SidebarElement {
  id: SideBarOptions;
  path?: AppRoutes;
  label: string;
  onClick: () => void;
  icon: JSX.Element;
}

export interface SidebarGroup {
  properties: {
    grow: boolean;
  };
  elements: SidebarElement[];
}

export const useIsMobileScreen = (breakpoint: MantineSize = 'sm') => {
  const theme = useMarkovTheme();
  const isMobileScreen = useMediaQuery(`(max-width: ${theme.breakpoints[breakpoint]})`);
  return isMobileScreen;
};

// Hook to encapsulate sidebar width calculation, as it is determined by several factors.
export const useSidebarWidth = () => {
  const { sidebarExpanded } = useLayoutContext();
  const { activeSection } = useAppMetadata();
  const { pathname } = useLocation();

  const sidebarWidthBase = 0;
  const sidebarWidthExpanded = NAV_BAR_COLLAPSED_SIZE;

  const secondaryNavbar = getFeatureFamilySidebar(activeSection);
  let sidebarWidth = sidebarExpanded ? sidebarWidthExpanded : sidebarWidthBase;
  const isUserProfile = getIsUserProfile(pathname);
  const shouldExtend = secondaryNavbar !== null && !isUserProfile && sidebarExpanded;
  if (shouldExtend) {
    sidebarWidth += NAV_BAR_COLLAPSED_SIZE;
  }

  return sidebarWidth;
};

export const useNonEnterpriseSidebarWidth = () => {
  const { sidebarExpanded } = useLayoutContext();

  if (!sidebarExpanded) {
    return 0;
  }

  // FIXIT: This code should be refactored. layout is created based on different requirements
  // of enterprise vs non-enterprise workspaces. Now overall code should be decoupled and should
  // be created based on this use case.

  const sidebarWidthBase = 0;
  const sidebarWidthExpanded = NAV_BAR_EXPANDED_SIZE;

  const sidebarWidth = sidebarExpanded ? sidebarWidthExpanded : sidebarWidthBase;

  return sidebarWidth;
};

export const getActiveBar = (pathname: string) => {
  if (pathname.includes(getRoute(AppRoutes.WORKFLOW_BUILDER))) {
    return SideBarOptions.WORKFLOWS_LIST;
  }
  if (pathname.includes(getRoute(AppRoutes.DATA_ANALYTICS))) {
    return SideBarOptions.DATA_ANALYTICS;
  }
  if (pathname.includes(getRoute(AppRoutes.WORKFLOWS_TEMPLATES))) {
    return SideBarOptions.WORKFLOWS_TEMPLATES;
  }
  if (pathname.includes(getRoute(AppRoutes.WORKFLOWS_LIST))) {
    return SideBarOptions.WORKFLOWS_LIST;
  }
  if (pathname.includes(getRoute(AppRoutes.WORKFLOW_APPS_LIST))) {
    return SideBarOptions.WORKFLOWS_APPS;
  }
  if (pathname.includes(getRoute(AppRoutes.WORKFLOWS_ARCHIVED))) {
    return SideBarOptions.WORKFLOWS_ARCHIVED;
  }
  if (pathname.includes(getRoute(AppRoutes.WORKFLOWS_HOME))) {
    return SideBarOptions.WORKFLOWS_HOME;
  }
  if (pathname.includes(getRoute(AppRoutes.APP_BUILDER))) {
    return SideBarOptions.APP_BUILDER;
  }
  if (pathname.includes(getRoute(AppRoutes.AI_APPS))) {
    return SideBarOptions.AI_APPS;
  }
  if (pathname.includes(getRoute(AppRoutes.PROJECTS))) {
    return SideBarOptions.PROJECTS;
  }
  if (pathname.includes(getRoute(AppRoutes.EXPERIMENTS))) {
    return SideBarOptions.EXPERIMENTS;
  }
  if (pathname.includes(getRoute(AppRoutes.EVALUATIONS))) {
    return SideBarOptions.EVALUATION;
  }
  // TODO: Clean up once AppRoutes.Recordings is cleaned from app
  if (pathname.includes(getRoute(AppRoutes.RECORDINGS))) {
    return SideBarOptions.EVALUATION;
  }
  if (pathname.includes(getRoute(AppRoutes.MODELS))) {
    return SideBarOptions.MODELS;
  }
  if (pathname.includes(getRoute(AppRoutes.MODEL_APPS))) {
    return SideBarOptions.MODEL_APPS;
  }
  if (pathname.includes(getRoute(AppRoutes.APP_STORE))) {
    return SideBarOptions.APP_STORE;
  }
  if (pathname.includes(getRoute(AppRoutes.DATASETS))) {
    return SideBarOptions.DATASETS;
  }
  if (pathname.includes(getRoute(AppRoutes.DATA_LABELING))) {
    return SideBarOptions.DATA_LABELING;
  }
  if (pathname.includes(getRoute(AppRoutes.SNIPPETS))) {
    return SideBarOptions.SNIPPETS;
  }
  if (pathname.includes(getRoute(AppRoutes.SETTINGS))) {
    return SideBarOptions.SETTINGS;
  }
  return SideBarOptions.HOME;
};
