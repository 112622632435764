import { ampli } from '@/main/amplitude';
import { Space, Text, closeAllModals, openModal } from '@/shared/design-system/v2';
import { WorkspaceSwitcherContainer } from './WorkspaceSwitcher.container';
import { InvitationContainer } from './invitation/Invitation.container';

export const useWorkspaceSwitcherModal = () => {
  const open = () => {
    ampli.switchWorkspaceClicked();

    openModal({
      size: 'lg',
      title: (
        // div needed to prevent nesting h2 elements
        <Text variant="heading02" component="div">
          Switch workspace
        </Text>
      ),
      children: (
        <>
          <InvitationContainer />
          <Space h="md" />
          <WorkspaceSwitcherContainer onClose={closeAllModals} />
        </>
      ),
    });
  };

  return { open };
};
