import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useToggle } from '@/shared/design-system/v2';
import { DEFAULT_PAGE_SIZE } from '@/shared/design-system/v2/core/data-display/table/util';
import { ArtifactStateType, Operator } from '../../../generated/api';
import { useGetWorkflowsQuery } from '../../../queries/workflows/list/list';
import { WorkflowsList } from './List';
import {
  FilterableFields,
  excludeDemoArtifactFilter,
  lockedOnlyFilter,
  scheduledOnlyFilter,
} from './util';

interface WorkflowsListContainerProps {
  state?: ArtifactStateType;
  loadingView?: JSX.Element;
  emptyView?: JSX.Element;
}

export const WorkflowsListContainer = ({
  state,
  loadingView,
  emptyView,
}: WorkflowsListContainerProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [showOnlyScheduled, toggleShowOnlyScheduled] = useToggle();
  const [showOnlyLocked, toggleShowOnlyLocked] = useToggle();

  const filters = useMemo(() => {
    const artifactStateFilter = {
      field: FilterableFields.ARTIFACT_STATE,
      operator: Operator.In,
      value: state,
    };

    const excludeArchivedFilter = {
      field: FilterableFields.ARTIFACT_STATE,
      operator: Operator.NotIn,
      value: ArtifactStateType.Archived,
    };

    const applicableFilters = [];

    applicableFilters.push(excludeDemoArtifactFilter);

    if (!state) {
      applicableFilters.push(excludeArchivedFilter);
    }

    if (state) {
      applicableFilters.push(artifactStateFilter);
    }

    if (showOnlyScheduled) {
      applicableFilters.push(scheduledOnlyFilter);
    }

    if (showOnlyLocked) {
      applicableFilters.push(lockedOnlyFilter);
    }

    return applicableFilters;
  }, [state, showOnlyScheduled, showOnlyLocked]);

  const { data, isError, isLoading, isFetching, refetch } = useGetWorkflowsQuery({
    filters,
    start: (currentPage - 1) * DEFAULT_PAGE_SIZE,
    searchQuery: searchText,
  });

  const handleShowOnlyScheduledCheckboxToggle = () => {
    toggleShowOnlyScheduled();
  };

  const handleShowOnlyLockedCheckboxToggle = () => {
    toggleShowOnlyLocked();
  };

  const handleSearchTextChange = (value: string) => {
    setSearchText(value);
    setCurrentPage(1);
  };

  if (isLoading && loadingView) {
    return loadingView;
  }

  if (isEmpty(data?.response) && emptyView) {
    return emptyView;
  }

  return (
    <WorkflowsList
      isLoading={isLoading || isFetching}
      searchText={searchText}
      onSearchTextChange={handleSearchTextChange}
      showOnlyScheduled={showOnlyScheduled}
      onShowOnlyScheduledCheckboxToggle={handleShowOnlyScheduledCheckboxToggle}
      showOnlyLocked={showOnlyLocked}
      onShowOnlyLockedCheckboxToggle={handleShowOnlyLockedCheckboxToggle}
      onReload={refetch}
      workflows={data?.response}
      hasError={isError}
      state={state}
      currentPage={currentPage}
      handlePageChange={setCurrentPage}
      totalRows={data?.numRecords ?? 0}
    />
  );
};
