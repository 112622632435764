import { IconArrowNarrowRight } from '@tabler/icons-react';
import {
  Box,
  Card,
  Horizontal,
  Image,
  Text,
  Vertical,
  useMarkovTheme,
} from '@/shared/design-system/v2';

const ILLUSTRATION_WIDTH = 332;
const TEXT_MAX_WIDTH = 485;

interface AppFeatureCardProps {
  title: string;
  description: string;
  ctaLabel: string;
  illustrationUrl: string;
  onClick: () => void;
}

export const AppFeatureCard = ({
  title,
  description,
  ctaLabel,
  illustrationUrl,
  onClick,
}: AppFeatureCardProps): JSX.Element => {
  const theme = useMarkovTheme();

  return (
    <Card withBorder onClick={onClick}>
      <Horizontal spacing="xxl" align="start">
        <Box w={ILLUSTRATION_WIDTH} sx={{ flexShrink: 0 }}>
          <Image src={illustrationUrl} />
        </Box>
        <Box pt="xxl" maw={TEXT_MAX_WIDTH}>
          <Vertical spacing="sm" h={120}>
            <Text variant="subTitle02" color="gray.8">
              {title}
            </Text>
            <Text variant="bodyShort03" color="dark.3">
              {description}
            </Text>
          </Vertical>
          <Horizontal noWrap spacing="sm" py="sm">
            <Text variant="subTitle05" color="blue.6">
              {ctaLabel}
            </Text>
            <IconArrowNarrowRight color={theme.colors.blue[6]} />
          </Horizontal>
        </Box>
      </Horizontal>
    </Card>
  );
};
