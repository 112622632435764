import { useGetDashboardQuery } from '@/main/queries/dashboard/dashboard';
import { Dashboard } from './Dashboard';
import { DashboardModel } from './util';

interface DashboardContainerProps {
  dashboardId: string;
}

export const DashboardContainer = ({ dashboardId }: DashboardContainerProps) => {
  const { isLoading, isError, data } = useGetDashboardQuery(dashboardId);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error...</div>;
  }

  if (!data) {
    return <div>No data...</div>;
  }

  return <Dashboard dashboard={data as DashboardModel} />;
};
