import { useNavigate } from 'react-router-dom';
import { sendAnalytics } from '@/main/initializers/analytics';
import { closeAllModals, notifications } from '@/shared/design-system/v2';
import { formatDateTime } from '@/shared/lib/ui';
import { workflowEvents } from '../../../../analytics';
import { useAppMetadata } from '../../../../contexts/app-metadata/AppMetadata';
import { useCreateWorkflowMutation } from '../../../../queries/workflows/builder';
import { useAbsoluteRoutes } from '../../../../router/hooks';

export const useAddWorkflow = () => {
  const { mutateAsync: createWorkflow } = useCreateWorkflowMutation();
  const { getWorkflowBuilderRoute } = useAbsoluteRoutes();
  const navigate = useNavigate();

  const handleAddWorkflow = async (templateId?: string, workflowName?: string) => {
    try {
      const data = await createWorkflow({ workflowName, templateId });
      if (data?.data?.workflowId) {
        closeAllModals();

        navigate({
          pathname: getWorkflowBuilderRoute(data.data.workflowId),
          search: templateId ? `?templateId=${templateId}` : '',
        });
      }
    } catch (e) {
      notifications.error('Error while creating workflow. Please try again and contact support');
    }
  };

  return { handleAddWorkflow };
};

export const useCreateBlankWorkflow = () => {
  const { workspaceId } = useAppMetadata();
  const { handleAddWorkflow } = useAddWorkflow();

  const createBlankWorkflow = () => {
    sendAnalytics(workflowEvents.create.blank({ workspaceId, source: 'workflows-home' }));
    const workflowName = `Untitled-${formatDateTime(new Date())}`;
    handleAddWorkflow('', workflowName);
  };

  return { createBlankWorkflow };
};
