import { Alert, Box, Center, Loader } from '@/shared/design-system/v2';
import { WorkflowRunContextProvider } from '../../../contexts/workflows/WorkflowRunContext';
import { TemplateModel, UpsertDAGRequest } from '../../../generated/api';
import {
  useGetOperatorsPublicListQuery,
  useGetTemplatesPublicListQuery,
} from '../../../queries/workflows/operators';
import { WorkflowViewer } from '../detail/viewer/WorkflowViewer';
import { toCamelCase } from './util';

interface PublicTemplatesContainerProps {
  templateId: string;
}

export const PublicTemplatesContainer = ({
  templateId,
}: PublicTemplatesContainerProps): JSX.Element => {
  const { isLoading, isError, data } = useGetTemplatesPublicListQuery();
  const {
    isLoading: operatorListLoading,
    isError: operatorListError,
    data: operatorList,
  } = useGetOperatorsPublicListQuery();

  const requiredTemplate = (data?.templates as TemplateModel[])?.find(
    template => template.templateId === templateId,
  );

  if (isLoading || operatorListLoading) {
    return (
      <Center h="100%">
        <Loader text="Loading template..." />
      </Center>
    );
  }

  if (isError || operatorListError || !requiredTemplate) {
    return <Alert color="red">Unable to find the template. Please try again</Alert>;
  }

  return (
    <Box bg="gray.1" h="100%">
      <WorkflowRunContextProvider workflowId="">
        <WorkflowViewer
          dag={requiredTemplate.dag as UpsertDAGRequest}
          operatorsList={toCamelCase(operatorList)}
          renderNodeActions
          isPublicTemplate
        />
      </WorkflowRunContextProvider>
    </Box>
  );
};
